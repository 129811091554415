import config from '../../../config';
import { getHighestRole, getRoles } from '../../auth/services';
import translation from '../../../translation/fr';
import formatDate from '../../formatDate/formatDate';

const getOne = async function getOne(
  resource: string,
  params: { [key: string]: string },
  requestHandler: any,
) {
  return (await requestHandler('GET_ONE', resource, params)).data;
};

const getMany = async function getMany(
  resource: string,
  params: { [key: string]: any },
  requestHandler: any,
) {
  return (await requestHandler('GET_MANY_REFERENCE', resource, params)).data;
};

interface EmailDeletePayload {
  className: string;
  classLevel: string;
  episodeNumber: string;
  episodeDate: string;
  establishmentName: string;
  establishmentCity: string;
  establishmentPostalCode: string;
  email: string;
  regionManager: string;
}

const sendEmail = async function sendEmail(payload: EmailDeletePayload) {
  const query = `
    mutation Email(
      $className: String!
      $classLevel: String!
      $episodeNumber: String!
      $episodeDate: String!
      $establishmentName: String!
      $establishmentCity: String!
      $establishmentPostalCode: String!
      $email: String!
      $regionManager: String!
    ) {
    episodeDeleteEmail(input: {
      className: $className
      classLevel: $classLevel
      episodeNumber: $episodeNumber
      episodeDate: $episodeDate
      establishmentName: $establishmentName
      establishmentCity: $establishmentCity
      establishmentPostalCode: $establishmentPostalCode
      email: $email
      regionManager: $regionManager
    }) {
      success
    }
  }`;

  return fetch(`${config.graphqlUri}/v1/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Hasura-Role': getHighestRole(getRoles()),
    },
    body: JSON.stringify({ query, variables: payload }),
  });
};

const episodeDelete = async function episodeDelete(
  episode: {
    classId: string;
    firstAnimateurId: string;
    secondAnimateurId: string;
    expertId: string;
    firstGuest: string;
    secondGuest: string;
    establishmentId: string;
    number: number;
    dateStart: string;
    regionManager: string;
  },
  requestHandler: any,
) {
  const pack = await getOne('class', { id: episode.classId }, requestHandler);
  const establishment = await getOne(
    'establishment',
    { id: pack.establishmentId },
    requestHandler,
  );
  const region = await getOne(
    'region',
    { id: establishment.regionId },
    requestHandler,
  );
  const regionManager = await getOne(
    'user',
    { id: region.managerId },
    requestHandler,
  );

  const firstAnimateur = episode.firstAnimateurId
    ? await getOne('user', { id: episode.firstAnimateurId }, requestHandler)
    : undefined;
  const secondAnimateur = episode.secondAnimateurId
    ? await getOne('user', { id: episode.secondAnimateurId }, requestHandler)
    : undefined;
  const expert = episode.expertId
    ? await getOne('user', { id: episode.expertId }, requestHandler)
    : undefined;
  const users = [firstAnimateur, secondAnimateur, expert].filter(
    user => !!user && user.isActive,
  );
  const emails = [
    ...users.map(user => user.email),
    episode.firstGuest,
    episode.secondGuest,
  ]
    .filter(email => !!email) // remove undefined
    .filter((value, index, self) => self.indexOf(value) === index); // remove duplicates

  return Promise.all(
    emails.map(email =>
      sendEmail({
        className: pack.name,
        classLevel: translation.resources.class.levels[pack.level],
        episodeNumber: episode.number.toString(),
        episodeDate: formatDate(episode.dateStart),
        establishmentName: establishment.name,
        establishmentCity: establishment.city,
        establishmentPostalCode: establishment.postalCode,
        email,
        regionManager: `${regionManager.firstName} ${regionManager.lastName}`,
      }),
    ),
  );
};

export const episodesDelete = async function episodesDelete(
  episodes,
  requestHandler: any,
) {
  return Promise.all(
    episodes.map(episode => episodeDelete(episode, requestHandler)),
  );
};

export const classDelete = async function classDelete(
  packs,
  requestHandler: any,
) {
  return Promise.all(
    packs.map(async pack => {
      const episodes = await getMany(
        'episode',
        {
          target: 'classId',
          id: pack.id,
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { classId: pack.id },
        },
        requestHandler,
      );
      return episodesDelete(episodes, requestHandler);
    }),
  );
};

export default episodeDelete;
