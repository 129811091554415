import episodeUpdate, { episodeCreate } from './episodeUpdate/episodeUpdate';
import { episodesDelete, classDelete } from './episodeDelete';
import trainingValidate from './trainingValidate';

export default {
  user: {
    UPDATE: (previousData, data, userId, handler) => {
      if (previousData && previousData.regionId !== data.regionId) {
        handler('UPDATE', 'user', {
          data: {
            oldRegionId: previousData.regionId,
            changeRegionDate: new Date(),
          },
          id: userId,
        });
      }
    },
    GET_ONE: async (res, handler) => {
      const data = await handler(
        'GET_MANY_REFERENCE',
        'user_region_animation',
        {
          target: 'user_id',
          id: res.data.id,
          sort: { field: 'region_id', order: 'DESC' },
          pagination: { page: 1, perPage: 50 },
        },
      );
      res.data.region_animation_ids = data.data.map(region => region.region_id);
    },
  },
  episode: {
    CREATE: episodeCreate,
    UPDATE: episodeUpdate,
    DELETE: episodesDelete,
    DELETE_MANY: episodesDelete,
  },
  class: {
    DELETE_MANY: classDelete,
  },
  user_training: {
    UPDATE: trainingValidate,
  },
};
