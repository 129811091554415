import React, { cloneElement } from 'react';
import {
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from 'react-admin';
import {
  getRoles,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from '../technical/auth/services';
import SpeakerEvalExportButton from './SpeakerEvalExportButton';
import {
  firstDayOfTheYearToString,
  lastDayOfTheYearToString,
} from './DateInput';

const ListActions = props => {
  const {
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    filterValues,
    permanentFilter,
    hasCreate,
    hasList,
    hasEdit,
    hasShow,
    exporter: rootExporter,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
  } = props;

  const roles = getRoles();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isShowOnlyRoles(roles) ? null : <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={rootExporter}
        maxResults={total}
      />
      {resource === 'region' && shouldSeeAllRegionsRoles(roles) ? (
        <SpeakerEvalExportButton
          dateStart={firstDayOfTheYearToString}
          dateEnd={lastDayOfTheYearToString}
        />
      ) : null}
      {resource === 'region' && shouldSeeAllRegionsRoles(roles) ? (
        <SpeakerEvalExportButton dateEnd={firstDayOfTheYearToString} />
      ) : null}
    </TopToolbar>
  );
};

export default ListActions;
